module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../../gatsby-source-umbraco-phoenix/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/home/vsts/work/1/s/packages/gatsby-source-umbraco-phoenix/.gatsby","rootPath":"/home/vsts/work/1/s/packages/gatsby-source-umbraco-phoenix","baseURL":"https://phx-nurofen-fr-prod.husky-2.rbcloud.io","xOrigin":["https://phx-nurofen-fr-prod.husky-2.rbcloud.io"]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TRKBPJ","includeInDevelopment":false,"timeout":2000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nurofen","short_name":"Nurofen","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/vsts/work/1/s/packages/gatsby-theme-nurofen/static/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2c6fbdd95a0cf9ad46ea3ffa198de1aa"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-155,"duration":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.nurofen.fr","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/home/vsts/work/1/s/packages/gatsby-theme-nurofen/gatsby","projectRoot":"/home/vsts/work/1/s/packages/gatsby-theme-nurofen","babel":false,"tsNode":true},
    },{
      plugin: require('../../gatsby-theme-nurofen/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
